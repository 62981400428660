export const poems = [
    {title:"Тепла осінь", published:"9.11.2023", id:"tepla-osin",poem:"На дворі листопад фарбує листя,<br>" +
            "а осінь все залишається теплою.<br>" +
            "Лишень холод усередині тебе,<br>" +
            "що поглинає тебе в цю безодню.<br>" +
            "<br>" +
            "Чогось не стало у твоєму житті.<br>" +
            "І як тая осінь, що втрачає свої барви,<br>" +
            "опалим листям на землю,<br>" +
            "ти втрачаєш все те, що тобі було цінним. <br>" +
            "І тепер ти як те дерево без листя.<br>" +
            "<br>" +
            "Але на весні воно знову одягнеться в зелені листочки,<br>" +
            "як і ти, що з часом закриєш свою пустоту,<br>" +
            "й новими рубцями теж що,<br>" +
            "залишаться назавжди на твоїм серці...<br>" +
            "<br>" +
            "Тепла осінь втрачає всі свої барви,<br>" +
            "Вона тільки відчувається такою теплою,<br>" +
            "Хоч як і ти вже стала холодною та сірою.<br>" +
            "<br>" +
            "Безлисті гілки дерев, кущів та сіре небо,<br>" +
            "описує всього тебе: сумного та морально пустого.<br>" +
            "Час веде до зими, перед снігом настане холод,<br>" +
            "такий же ж самий холод, що у твоєму серці.<br>" +
            "Зима все зробить сніжно-білим, а тебе покриє льодом..."},
    {title:"Гріхи", published:"21.08.2023", id:"hrikhy", poem:"Потойбічний світ цікавий, чи не так? <br>" +
            "Пекло чи Рай, як ті дві дороги<br>" +
            "Але твоя веде до Пекла<br>" +
            "Ти готовий спокутувати провину? <br>" +
            "<br>" +
            "Твоїм тілом буду ласувати ворони<br>" +
            "Так, як ти ласував награбованим врожаєм,<br>" +
            "Очі стануть золотими <br>" +
            "Так, як їх спокушувало золото, котре ти крав<br>" +
            "Не зважаючи ні нащо<br>" +
            "<br>" +
            "Ти будеш відчувати тую біль, <br>" +
            "яку завдам всім<br>" +
            "Фізичну й моральну<br>" +
            "<br>" +
            "Ти молився Богу, ходив до церкви<br>" +
            "“Але чому ж я тут”<br>" +
            "<br>" +
            "А все тому, що ти не вірив у Бога<br>" +
            "Ти вірив у те, що тобі наговорили<br>" +
            "Такі ж самі нечистота, <br>" +
            "що жила таким же ж життям, як і ти. <br>" +
            "<br>" +
            "Який вірив, що помилившись все пройде<br>" +
            "Й знову можна робити гріхи.<br>" +
            "Твоє життя це суцільне лицемірство<br>" +
            "<br>" +
            "Ти думав, що молишся та сповідуєш заповіді Хрестові<br>" +
            "А натомість молився та розповідав свої провини мені<br>" +
            "<br>" +
            "Твоя душа буде роз’їдатися сором <br>" +
            "Тіло, клювати птахи, вічно, як того Прометея<br>" +
            "Спокутування вини буде вічною мукою"},
    {title:"Ми всі помремо", published:"05.01.2024", id:"my-vsi-pomremo", poem:"Ми помремо заливаючися кров'ю, <br>" +
            "вона стікає по наших горлах.<br>" +
            "Хто би міг подумати, <br>" +
            "що погана затіє була робити це.<br>" +
            "<br>" +
            "Гибель застала так неочікувано.<br>" +
            "Вмиваючись власною кров'ю, <br>" +
            "змиваємо свої ж і гріхи.<br>" +
            "\"Отче наш, прийми їхні душі\"   <br>" +
            "Промовляє все далі чоловік у халаті.<br>" +
            "А наша кончина настала захлипнувшись.<br>" +
            "Кров була така гаряча, <br>" +
            "й стікала вона немов рікою,<br>" +
            "омиваючи наші тіла. <br>" +
            "<br>" +
            "Що за усмішка з'явилася на твоїх вустах? <br>" +
            "Від чого тобі так стало же смішно?<br>" +
            "<br>" +
            "У цьому коридорі проявлявся силует.<br>" +
            "Жінка у чорному халаті, а за пазухою коса.<br>" +
            "Кістлява рука протягнулася до твого обличчя.<br>" +
            "Її поцілунок смерть став останнім у твому житті.<br>" +
            "Він забрав твоє життя, <br>" +
            "й тепер ти її рабиня. <br>" +
            "<br>" +
            "Наймичка у своєї смерті, <br>" +
            "цікавою ж твоя доля стала.<br>" +
            "<br>" +
            "А по мене прийшов злісний демон. <br>" +
            "Його руки залікували моє горло, <br>" +
            "лиш шрам у всю довжину залишився,<br>" +
            "але це не була милостиня, а болісні муки.<br>" +
            "Горло стискалося, я не міг і дихнути, <br>" +
            "але й померти не міг, бо душу душив.<br>" +
            "<br>" +
            "Той чоловік не збрехав же ж.<br>" +
            "Платимо за свої гріхи.<br>" +
            "А, на меж завжди толочили: <br>" +
            "\"Що посієш, то й пожнеш\".<br>" +
            "Каяття не минучі,<br>" +
            "тільки ніхто нас не просте. "},
    {title:"Про твою смерть", published:"21.12.2023", id:"pro-tvoyu-smert", poem:"Рано чи пізно вона настигне тебе.<br>" +
            "І прийде до тебе ця карга,<br>" +
            "у будь-якому обліку до тебе.<br>" +
            "<br>" +
            "Це може бути хвороба, <br>" +
            "що пожиратиме тебе з середини.<br>" +
            "Вона знайде спосіб як тебе помучити,<br>" +
            "Перед твоєю кончиною.<br>" +
            "Або взагалі доведе тебе до сказу,<br>" +
            "що б ти сам собі кулю в лоба пустив.<br>" +
            "А може тобі просто не повезе,<br>" +
            "і який блазень, що вирішив<br>" +
            "Одягнути на себе цю роль<br>" +
            "Уб'є тебе, бо ти був під його рукою.<br>" +
            "<br>" +
            "І струпом покриється твоє тіло,<br>" +
            "стане шведським столом для птиці,<br>" +
            "що пожиратиме тебе, ласувати тобою,<br>" +
            "Нутрощі розтерзають, <br>" +
            "згниють твої останки та<br>" +
            "Тільки лиш кістки залишаться.<br>" +
            "<br>" +
            "\"Чи добре ти прожив життя?\"<br>" +
            "Запитає тебе вона,<br>" +
            "Ведучи за руку до суду праведного, <br>" +
            "Де буде суджена твоя наступна доля.<br>" +
            "<br>" +
            "Це можуть бути довічні муки.<br>" +
            "Або знову проживеш нове життя,<br>" +
            "знову як людина, якщо вважатимуть<br>" +
            "Це належним чином до тебе.<br>" +
            "Або реінкарнація у щось або когось.<br>" +
            "Можливо змилуються над твоєю<br>" +
            "Та дадуть спочити з миром,<br>" +
            "але це вже все залежить лишень від тебе.<br>" +
            "<br>" +
            "Вони будуть дивитися, який було твоє життя,<br>" +
            "як ти його прожив та якою була гибель, <br>" +
            "візьму до уваги всі твої вчинки,<br>" +
            "якими б вони не були.<br>" +
            "<br>" +
            "Задумайся як ти живеш,<br>" +
            "Бо хто його знає, <br>" +
            "Коли смерть настигне тебе...."},
    {title:"Лікуюча зима", published:"21.11.2023", id:"likuyucha-zyva", poem:"Снігом покрила все навколо <br>" +
            "І мою душу заодно<br>" +
            "Серце в брилу перетворила,<br>" +
            "Кров холодною зробила,<br>" +
            "А з нею і розум.<br>" +
            "<br>" +
            "Нові, розважливі й раціональні думки,<br>" +
            "Заповнюють мій розум.<br>" +
            "І я не знаю, що мені робити,<br>" +
            "куди діватися та мені,<br>" +
            "Моїй душі заблудшій у цих лісах.<br>" +
            "Тихих, холодних та сніжних.<br>" +
            "<br>" +
            "Хвоя залишається зеленою,<br>" +
            "А цим і прекрасною, <br>" +
            "Своєю білою накидкою.<br>" +
            "<br>" +
            "І я броджу поміж дерев,<br>" +
            "Шукають відповіді — вихід.<br>" +
            "І все не знаю, що мені робити.<br>" +
            "Немає звірів навкруги, лише я,<br>" +
            "ходжу поміж цих дверей,<br>" +
            "А душа моя стає легшою,<br>" +
            "Наче тягарі спадають з неї.<br>" +
            "<br>" +
            "Сніг як тая губка забирає все в себе.<br>" +
            "Я чекаю, коли прийде весна,<br>" +
            "Що дасть надію мені в цьому житті, <br>" +
            "А поки зима, залікує мої рани,<br>" +
            "Нехай залишаться лише рубці того, <br>" +
            "Що відбулося в моєму житті.<br>" +
            "<br>" +
            "Нові рубці, як ті уроки,<br>" +
            "Я пройшов вже чимало їх від життя, <br>" +
            "Але ж їх буде тільки більше,<br>" +
            "А зима все знову й знову лікуватиме мене,<br>" +
            "Мою заблудшу душу, що потраплятиме сюди,<br>" +
            "Кожного разу після теплої осені...<br>" +
            "<br>" +
            "Яка окутала мене у свій цвіт троянди,<br>" +
            "Що своїми колючками на стеблі,<br>" +
            "Здерла мені шкіру, і я облився кров'ю своєю.<br>" +
            "<br>" +
            "Що ж буде далі в моєму житті?<br>" +
            "Та й коли пройде ця холодна зима..."},
    {title:"Польоти у мріях ", published:"19.12.2023", id:"poloty-u-mriyakh", poem:"Перед сном завжди вони є,<br>" +
            "коли тільки закривши очі.<br>" +
            "Вже знаходишся на злітній смузі.<br>" +
            "Поміж мрій, уявляючи,<br>" +
            "Де немає нічого жахливого,<br>" +
            "Де ти стаєш ми ким і хочеш бути.<br>" +
            "<br>" +
            "І тепер сон немов реальний,<br>" +
            "а ти сам собі герой у цій історії.<br>" +
            "Летиш все вище, вище хмар,<br>" +
            "І потаємні мрії всі реалізуються.<br>" +
            "Тільки є одна проблема,<br>" +
            "Коли прокинешся, від будильника,<br>" +
            "настрою і наснаги,<br>" +
            "а синці під очима псують твою зовнішність.<br>" +
            "<br>" +
            "Польоти в мріях такі чудові та приємні,<br>" +
            "тільки всі вони не є реальними.<br>" +
            "Хоч і розумієш це,<br>" +
            "але все одно мрієш зустрітися з тою самою,<br>" +
            "реалізувати ту саму потаємну мрію,<br>" +
            "але без дій добитися цього.<br>" +
            "І все воно залишається тільки там.<br>" +
            "Ти дуриш сам себе, літаючи там!<br>" +
            "<br>" +
            "А час іде, його не зупинити, <br>" +
            "Поміж хмар ти завжди молодий,<br>" +
            "А в реальності вже випадають пасма,<br>" +
            "Струнке тіло перетворюється на кулю жиру.<br>" +
            "Приємні польоти стали вбивати тебе.<br>" +
            "Все ж хороше в міру чи не так?<br>" +
            "Добре іноді пофантазувати, <br>" +
            "але коли робиш це постійно, <br>" +
            "то розчарування вже взяло тебе за руку.<br>" +
            "<br>" +
            "Ви всі рано чи пізно помрете, <br>" +
            "І кожному суджено свій час.<br>" +
            "Чи вистачить тобі його?<br>" +
            "Чи зможеш ти все реалізувати?<br>" +
            "<br>" +
            "А я побуду в стороні.<br>" +
            "У мене немає нічого такого,<br>" +
            "Моє життя вічне й робота одна й та ж,<br>" +
            "Зрозумій, ти не вічний.<br>" +
            "Ти не перший, кого я намагаюся врятувати, <br>" +
            "Ми зустрінемося, коли продзвенить останній дзвін,  <br>" +
            "У твоєму житті, яке створюєш ти сам!<br>" +
            "<br>" +
            "Моя кістлява рука забере тебе.<br>" +
            "Перед судом постане твоя душа.<br>" +
            "І ми підемо туди, куди тобі судитиметься.<br>" +
            "Та не забувай істини одної,<br>" +
            "все у твоїх руках, пам'ятай про це!<br>" +
            "<br>" +
            "Зрозумій же ж ти, <br>" +
            "Польоти прекрасні та чарівні, <br>" +
            "Але ж вони немов наркотик,<br>" +
            "Візьмися ж ти й полети.<br>" +
            "Мов той яструб, тільки у живу!<br>" +
            "<br>" +
            "А ми почекаємо,<br>" +
            "Нікуди нам спішити."},
    {title:"Старі спогади", published:"01.06.2024", id:"stari-spohady", poem:"Лиш сум залишився з того часу,<br>" +
            "колись прекрасні миті перетворилися в старі спогади,<br>" +
            "що інколи повертаються назад, <br>" +
            "але замість усмішки викликають журбу на серці. <br>" +
            "Колись близькі та рідні, <br>" +
            "нині чужинці, що знають моє нутро. <br>" +
            "<br>" +
            "І є розуміння чому так все сталося, <br>" +
            "І не мало б лишитись хорошого з того, <br>" +
            "але беручи знову ці фотографії до рук, <br>" +
            "згадуєш кожну мить, <br>" +
            "час, що тоді був, <br>" +
            "запах, котрий лунав тоді.<br>" +
            "<br>" +
            "Так важко висловити це все, <br>" +
            "що давить всередині дивлячись на це, але...<br>" +
            "Серце дедалі б'ється окутаним журбою, <br>" +
            "немов від мене відірвали частину самого себе,<br>" +
            "а те, що залишилося оніміло смутою.<br>" +
            "<br>" +
            "Хоч і вилилося багато води з того, <br>" +
            "сам ставши вже зовсім іншим, <br>" +
            "але спогади про них досі є, <br>" +
            "ті люди, що звали себе рідними,<br>" +
            "пішли забравши з собою все хороше,<br>" +
            "звинувачуючи мене у всьому. <br>" +
            "<br>" +
            "Тютюновий дим знову наповнював легені, <br>" +
            "і ставало легше на серце,<br>" +
            "немов з димом вивітрювала ця смута.<br>" +
            "<br>" +
            "Їх було троє. Троє рідних мені, <br>" +
            "але лишилась лиш одна з них,<br>" +
            "та, що менше всіх кричала за братерство..."},
    {title:"Сни", published:"21.08.2023", id:"sny", poem:"Очі поступово злипаються,<br>" +
            "бажання сну стає сильнішим.<br>" +
            "Але як же ж спати без неї?<br>" +
            "Я не можу заснути без неї.<br>" +
            "А якщо щось трапиться?<br>" +
            "Скільки думок та переживань<br>" +
            "Спати не можна. Ну ніяк.<br>" +
            "Без неї нікуди, як спати, то з нею<br>" +
            "Але не ясно, коли спати вона буде…<br>" +
            "<br>" +
            "У навушниках лунає музика<br>" +
            "Я стараюся не заснути<br>" +
            "Дочекатися її…<br>" +
            "Це може бути перша, друга<br>" +
            "А може і навіть третя ночі…<br>" +
            "Але без неї – нікуди<br>" +
            "<br>" +
            "Хочу бути з нею<br>" +
            "Навіть уві сні<br>" +
            "Бо як же ж мені бути без неї?<br>" +
            "Мої думки постійно в ній<br>" +
            "Почуття прив'язали мене до неї<br>" +
            "А її до мене…"},
    {title:"Моя мова", published:"04.07.2024", id:"moya-mova", poem:"Я пишу ці рядки <br>" +
            "Коли йде безсонна ніч, <br>" +
            "вже й збився в їх рахунку,<br>" +
            "руки мимоволі набирають слова.<br>" +
            "<br>" +
            "З дна на день ідуть сірі дні,<br>" +
            "Що вже й не просто монотонні,<br>" +
            "А копія попереднього, <br>" +
            "то починаєш втрачати себе,<br>" +
            "Немає почуття як якось, <br>" +
            "Вигорівши на нівець зникають відчуття,<br>" +
            "Вже й немає того, що принесе задоволення,<br>" +
            "Все мрякле і сіре,<br>" +
            "Немов убите журбою.<br>" +
            "<br>" +
            "І я немов став на дорогу самогубства,<br>" +
            "Все більше і більш намагаючись стати краще,<br>" +
            "Все глибше тону в собі,<br>" +
            "А фарби гуснуть і тьмяніють.<br>" +
            "У голові 24/7 шум,<br>" +
            "що прагну перебити чимось.<br>" +
            "<br>" +
            "Я втратив самого себе,<br>" +
            "Не знаю хто я та що я.<br>" +
            "Немов все руйнується під ногами, <br>" +
            "І лиш залишається писати,<br>" +
            "Набираючи слово за словом,<br>" +
            "Слова стають рядками,<br>" +
            "А рядки моєю мовою,<br>" +
            "Мовою тої прірви <br>" +
            "в яку ж я сам себе загнав.<br>" +
            "<br>" +
            "Я би хотів писати про чудове життя, <br>" +
            "Але як про нього писати коли його й нема?<br>" +
            "І біжучи по безкрайніх думках <br>" +
            "Лиш глибше тону в прірві свідомості,<br>" +
            "Лиш діри на дірах,<br>" +
            "Ніякої рівної смуги в дорозі життя<br>" +
            "<br>" +
            "А життя іде і тягне мене по цій смузі,<br>" +
            "Що стає лиш тернистішою.<br>" +
            "Можливо десь там майорить зоря моя,<br>" +
            "що надасть ясних фарб мові моїй,<br>" +
            "Але це десь за обрієм моїх мрій.<br>" +
            "<br>"},
]

    export const projects = [
        {
            title:"finances",
            version:"4 build 11 release Vol. 1",
            about:"<b>finances</b> — Telegram bot for convenient exchange rate and conversion of currencies/cryptocurrencies and stocks overview.<br>" +
                "It is a convenient tool that can be used as you wish. In personal messages with the bot or with your interlocutor (write @fStatisticsBot or go to the bot) and group.<br>" +
                "Customise it to your own needs as you see fit.",
            news:"we add new penis",
            id:"finances",
            urls:{
                telegram:"https://t.me/fStatisticsBot/",
                github:"https://github.com/teferdet/finances",
            }
        }
    ]
